// JavaScript Document
$.material.init();
$(document.body).on('change', '.placeholder-select', function () {
    if (this.value == "") { $(this).addClass("empty"); } else { $(this).removeClass("empty"); }
});
$(".placeholder-select").change();

//-----Accordian----//

var selectIds = $('#panel1,#panel2,#panel3,#panel4');

$(function ($) {
    selectIds.on('show.bs.collapse hidden.bs.collapse', function () {

        $(this).prev().find('.fa').toggleClass('fa-angle-down');
    });

    $("#querybuilder .panel-heading").on('click', function () {
        $("#querybuilder .panel-heading").removeClass("clicked");
        var shouldToggle = $(this).next().hasClass("in");
        if (!shouldToggle) {
            $(this).addClass("clicked");
        } else {
            $(this).removeClass("clicked");
        }
    });
});


$(".dropdown").click(function (event) {
    // stop bootstrap.js to hide the parents
    event.stopPropagation();
    // hide the open children
    $(this).find(".submenu").removeClass('open');
    // add 'open' class to all parents with class 'dropdown-submenu'
    $(this).parents(".submenu").addClass('open');
    // this is also open (or was)
    $(this).toggleClass('open');
});

$(document).ready(function () {

    //-------role permission toggel-------//

    $("#absolute-btn").click(function () {
        $("#Absolute").removeClass("form-box");
        $("#Band").addClass("form-box");
        $('#disabled').prop("disabled", true);
        $('#disabled').addClass("disabled");
    });

    $("#band-btn").click(function () {
        $("#Band").removeClass("form-box");
        $("#Absolute").addClass("form-box");
        $('#disabled').prop("disabled", false);
        $('#disabled').removeClass("disabled");
    });
});

//-------Right Slide Pannel Model ------//
$(document.body).on('click', '.right-hbox-model', function () {
    var target = "#" + $(this).data("target");
    var divwidth = $(target).width();
    var parseint = parseInt($(target).css("right"));
    if (parseint == 0) {
		$(target).css("right", -divwidth);
		if(target!= "#viewstructure_container"){
		$(".tree-container").attr("id","fullview");
		$(".gridHeaderShrink").addClass("gridHeader");
		$(".gridHeader").removeClass("gridHeaderShrink");
		$(".tree-container").css("width","100%");
		}

    } else {
		$(target).css("right", 0);
		if(target!= "#viewstructure_container"){
		$(".tree-container").attr("id","shrinkview");
		$(".gridHeader").addClass("gridHeaderShrink");
		$(".gridHeaderShrink").removeClass("gridHeader");
		$(".tree-container").css("width","70%");
        }
    }

    $(this).find('i').toggleClass('fas fa-angle-left fas fa-angle-right');
});

//-------End Right Slide Pannel Model-------//


$(document.body).on('click', '#noti-groupby a', function () {
    $('.target-select').html($(this).html() + '<span class="caret"></span>');
})

//---tactical table td Expad collapse---//
$('.collapse').on('show.bs.collapse', function () {

});

$(document.body).on('click', '.add-plus-btn[data-toggle="collapse"]', function () {
    if ($(this).find('.fa').hasClass('fa-angle-down')) {
        $(this).find('.fa').removeClass('fa-angle-down');
        $(this.target).collapse('hide');
    } else {
        $(this).find('.fa').addClass('fa-angle-down');
        $(this).parent().parent().addClass('inboxactive');
        $(this.target).collapse('show');
    }
});

//---End tactical table td Expad collapse---//

$(document.body).on('click', '#collapseIcon1', function () {
    $('#collapseIcon1').find('i').toggleClass('fa-caret-down fa-caret-up')


});
$(document.body).on('click', '#collapseIcon2', function () {
    $('#collapseIcon2').find('i').toggleClass('fa-caret-up fa-caret-down')
});

$(document.body).on('click', '#collapseIcon3', function () {
    $('#collapseIcon3').find('i').toggleClass('fa-caret-up fa-caret-down ')
});

$(document.body).on('click', '#collapseIcon4', function () {
    $('#collapseIcon4').find('i').toggleClass('fa-caret-up fa-caret-down ')
});

$(document.body).on('click', '#collapseIcon5', function () {
    $('#collapseIcon5').find('i').toggleClass('fa-caret-up fa-caret-down ')
});

$(document.body).on('click', '#collapseIcon6', function () {
    $('#collapseIcon6').find('i').toggleClass('fa-caret-up fa-caret-down ')
});

// --------------Query Builder-----------------//

$(".right-hbox, .noti-btn-toggle").click(function () {
    $(".left-hbox-container").toggleClass("toggle-box-width");
    $(".tree-table-grid").toggleClass("maintenance-pending");
});

$(document.body).on('click', '.progress-close', function () {
  $(this).parent().toggleClass('running-bar-circle');
});

$(document.body).on('click', '.bar-pin', function () {
    $(this).parent().parent().parent().toggleClass('db-message-bar-circle');
});
